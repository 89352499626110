import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import ContactBar from "../../components/ContactBar";
import SingleImage from "../../library/SingleImage";
import Layout from "../../components/Layout";

const SpermiogrammPage = (props) => (
  <Layout location={props.location}>
    <div className="page offer">
      <Helmet>
        <title>Kinderwunsch</title>
        <meta
          name="description"
          content="Ein pathologisches Spermiogramm des Mannes kann zu 50% der Grund des unerfüllten Kinderwunsches sein."
        />
        <meta property="og:title" content="Pathologisches Spermiogramm" />
        <meta
          property="og:description"
          content="Ein pathologisches Spermiogramm des Mannes kann zu 50% der Grund des unerfüllten Kinderwunsches sein."
        />
      </Helmet>
      <ContactBar className="large-screen" />
      <div className="container">
        <h1>Pathologisches Spermiogramm</h1>
        <SingleImage image={props.data.spermiogramm.fluid} />
        <p>
          Ein pathologisches Spermiogramm des Mannes kann zu 50% der Grund des
          unerfüllten Kinderwunsches sein.
        </p>
        <p>
          Da die Spermiogenese (Bildung) in einem Zyklus von 90 Tagen abläuft,
          kann schon innerhalb von 3 Monaten ein deutlich besseres Ergebnis
          erzielt werden.
        </p>
        <p>
          Hitze aufgrund von Entzündungen, schlechte Spermienqualität durch
          Stress oder Noxen, wie Nikotin, Medikamenten oder
          Beschäftigungsbedingten Giften können mit Akupunktur und chinesischen
          Kräutern sehr effektiv behandelt werden.
        </p>
        <div className="cta">
          <p>Buchen Sie noch heute einen Termin!</p>
          <a
            className="button"
            href="https://www.coachfrog.ch/de/fachperson/paola-hoffmann/?bookingOpen=1"
            target="_blank"
            rel="noopener noreferrer"
          >
            Online buchen
          </a>
        </div>
      </div>
    </div>
  </Layout>
);

export default SpermiogrammPage;

export const query = graphql`
  query SpermiogrammImageQuery {
    spermiogramm: imageSharp(
      fluid: { originalName: { regex: "/spermiogramm/" } }
    ) {
      fluid(maxHeight: 580, cropFocus: ENTROPY) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;
